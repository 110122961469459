@font-face {
font-family: '__NotoSans_77be52';
src: url(/_next/static/media/e841518cddaf09e8-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__NotoSans_77be52';
src: url(/_next/static/media/e841518cddaf09e8-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__NotoSans_77be52';
src: url(/_next/static/media/b3da945848748950-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__NotoSans_77be52';
src: url(/_next/static/media/b3da945848748950-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__NotoSans_77be52';
src: url(/_next/static/media/a45867c24a11f593-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__NotoSans_77be52';
src: url(/_next/static/media/a45867c24a11f593-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__NotoSans_Fallback_77be52';src: local("Arial");ascent-override: 100.41%;descent-override: 27.52%;line-gap-override: 0.00%;size-adjust: 106.47%
}.__className_77be52 {font-family: '__NotoSans_77be52', '__NotoSans_Fallback_77be52'
}.__variable_77be52 {--executive-website-regular-font-family: '__NotoSans_77be52', '__NotoSans_Fallback_77be52'
}

@font-face {
font-family: '__Amiri_6a467c';
src: url(/_next/static/media/7c89872b5a4cb391-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__Amiri_6a467c';
src: url(/_next/static/media/7c89872b5a4cb391-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Amiri_6a467c';
src: url(/_next/static/media/48178dec4aeb2c98-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__Amiri_6a467c';
src: url(/_next/static/media/48178dec4aeb2c98-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__Amiri_Fallback_6a467c';src: local("Arial");ascent-override: 124.28%;descent-override: 70.10%;line-gap-override: 0.00%;size-adjust: 90.44%
}.__className_6a467c {font-family: '__Amiri_6a467c', '__Amiri_Fallback_6a467c'
}.__variable_6a467c {--executive-website-heading-font-family: '__Amiri_6a467c', '__Amiri_Fallback_6a467c'
}

